$(document).ready(function() {
  $('.change-entry-name-button').click(function(e) {
    var entryId = $(e.currentTarget).data('id');
    $('.change-entry-name-form-' + entryId).toggle();
  });

  var windowHeight = $(window).height();
  var mainHeight = $('.main-content').height();

  if (mainHeight < windowHeight) {
    $('.main-content').css('min-height', windowHeight);
  }

  $(window).resize(function() {
    windowHeight = $(window).height();

    if (mainHeight < windowHeight) {
      $('.main-content').css('min-height', windowHeight);
    }
  });

  // Toggle between live entries and all entries.
  if ($('.entry-eliminated').length === 0) {
    $('.showing-live').hide();
  }
  $('.show-all-entries-btn').click(function() {
    $('.entry-eliminated').show();
    $('.showing-all').show();
    $('.showing-live').hide();
  });
  $('.show-live-entries-btn').click(function() {
    $('.entry-eliminated').hide();
    $('.showing-all').hide();
    $('.showing-live').show();
  });
});
